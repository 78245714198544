import React, { FC } from 'react';
import { StepsValue } from './App';
import CountView from './View/Count/CountView';

export enum CountValues {
  One = '1',
  Two = '2',
  Three = '3',
}

const countValues = [CountValues.One, CountValues.Two, CountValues.Three];

interface IProps {
  setStep(step: StepsValue): void;
  setCount(count: CountValues): void;
}

const Count: FC<IProps> = ({ setStep, setCount }) => (
  <div>
    {countValues.map(v => (
      <div key={v} onClick={() => {
        setStep(StepsValue.Color);
        setCount(v)
      }}>
        <CountView count={v}/>
      </div>
    ))}
  </div>
);

export default Count;
