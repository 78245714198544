import React, { FC } from 'react';
import { ColorValues } from './Color';
import { CountValues } from './Count';
import { ShapeValues } from './Shape';
import { FillValues } from './Fill';
import { Card, NewStepsValue } from './App';
import Cards from './Cards';

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
function shuffle(a: any) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const getAllCards = (): Card[] => {
  const cards: Card[] = [];

  Object.values(ColorValues).forEach(color => {
    Object.values(CountValues).forEach(count => {
      Object.values(ShapeValues).forEach(shape => {
        Object.values(FillValues).forEach(fill => {
          cards.push({
            color,
            count,
            shape,
            fill,
          });
        });
      });
    });
  });

  return shuffle(cards);
};

export const getCards = (color: ColorValues, count: CountValues, shapeFilter?: ShapeValues, fillFilter?: FillValues ): Card[] => {
  const cards: Card[] = [];
  Object.values(ShapeValues).forEach(shape => {
    Object.values(FillValues).forEach(fill => {
      if (shapeFilter && shape !== shapeFilter) {
        return;
      }

      if (fillFilter && fill !== fillFilter) {
        return;
      }

      cards.push({
        color,
        count,
        shape,
        fill,
      });
    });
  });

  return cards;
};

interface Step1Props {
  color: ColorValues;
  count: CountValues;
  selectedCards: Card[];

  setNewStep(step: NewStepsValue): void;
  setShape(shape: ShapeValues): void;
  setFill(fill: FillValues): void;
  setCards(cards: Card[]): void;
}

const isTheSameCard = (card1: Card, card2: Card): boolean => {
  return card1.fill === card2.fill && card1.shape === card2.shape && card1.color === card2.color && card1.count === card2.count;
};

const Step2: FC<Step1Props> = ({ color, count, setShape, setFill, setCards, setNewStep, selectedCards }) => {
  return (
    <div>
      {Object.values(ShapeValues).map(shape => (
        <Cards
          key={shape}
          setNewStep={setNewStep}
          selectedCards={selectedCards}
          setCards={setCards}
          setFill={setFill}
          setShape={setShape}
          cards={getCards(color, count, shape).filter(c => {
            const existingCard = selectedCards.find(sc => isTheSameCard(sc, c));
            return !existingCard;
          })}
        />
      ))}
    </div>
  );
};

export default Step2;