import React, { FC } from 'react';
import { Card, NewStepsValue } from './App';
import { getCountValues, ShapeValues } from './Shape';
import SymbolView from './View/SymbolView';
import { FillValues } from './Fill';

interface ICardProps {
  card: Card;
  cards: Card[];
  index: number;
  selectedCards?: Card[];
  showRemoveButton?: boolean;

  setShape?(shape: ShapeValues): void;
  setFill?(fill: FillValues): void;
  setCards?(cards: Card[]): void;
  setNewStep?(step: NewStepsValue): void;
}

const CardView: FC<ICardProps> = (
  {
    card,
    cards,
    index,
    selectedCards,
    setShape,
    setFill,
    setCards,
    setNewStep,
    showRemoveButton,
  }) => {
  return (
    <div className='card-wrapper'>
      <div className='card' onClick={() => {
        if (setShape) {
          setShape(card.shape);
        }

        if (setFill) {
          setFill(card.fill);
        }

        if (setShape && setFill && setCards && setNewStep && selectedCards) {
          setCards([...selectedCards, card]);
          setNewStep(NewStepsValue.CountColor);
        }
      }}>
        {getCountValues(card.count).map(i => <SymbolView key={i} card={card} />)}
      </div>
      {showRemoveButton && setCards &&
      <div className='button-wrapper'>
          <button onClick={() => {
            setCards(cards.filter((c, i) => i !== index));
          }}>X</button>
      </div>
      }
    </div>
  );
};

export default CardView;