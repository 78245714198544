import React, { useState } from 'react';
import { CountValues } from './Count';
import { ColorValues } from './Color';
import { ShapeValues } from './Shape';
import { FillValues } from './Fill';
import './index.scss';
import Step1 from './Step1';
import Cards from './Cards';
import Step2, { getAllCards, getCards } from './Step2';

const NUMBER_OF_CARDS = 12;

export enum ActionTypes {
  SelectCards,
  StartGame
}

export enum StepsValue {
  Count,
  Color,
  Shape,
  Fill,
}

export enum NewStepsValue {
  CountColor,
  ShapeFill,
}

export interface Card {
  count: CountValues;
  color: ColorValues;
  shape: ShapeValues;
  fill: FillValues;
}


// function onlyUnique(value: string, index: number, self: string[]) {
//   return self.indexOf(value) === index;
// }

let counter = 0b0;
const comparison = [];
const twelve = "111111111111";
while (counter.toString(2) !== twelve) {
  const str = (counter).toString(2).replace(/0/g, "");
  if (str.length === 3) {
    let bin = counter.toString(2);

    while (bin.length < 12) {
      bin = `0${bin}`;
    }
    comparison.push(bin);
  }
  counter += 0b1;
}
// console.log(comparison);

const indexes: any[] = [];
comparison.forEach(item => {
  const bins = item.split('');
  const localIndexes = bins.map((bin, index) => {
    if (bin === "1") {
      return index;
    }
    return undefined;
  });
  indexes.push(localIndexes.filter(i => typeof i !== 'undefined'));
});

// const validateCard = (c: string): boolean => {
//   if (!c) {
//     return false;
//   }
//   // @ts-ignore
//   const [count, color, shape, fill] = c;
//   if (
//     (!['1', '2', '3'].includes(count)) ||
//     (!['R', 'P', 'G'].includes(color)) ||
//     (!['O', 'M', 'W'].includes(shape)) ||
//     (!['E', 'F', 'S'].includes(fill))
//   ) {
//     // throw new Error(`${c} is invalid!`);
//     return false;
//   }
//
//   return true;
// };

const onCalc = (cards: string[]) => {
  // if (cards.filter(onlyUnique).length !== NUMBER_OF_CARDS) {
  //   throw new Error('Not unique cards.');
  // }

  // cards.forEach(validateCard);

  const isEquals = (items: string[]) => {
    const [a, b, c] = items;
    return a === b && a === c && b === c;
  };

  const isDifferent = (items: string[]) => {
    const [a, b, c] = items;
    return a !== b && a !== c && b !== c;
  };

  const getIsSet = (cards: string[]) => {

    const counts = [];
    counts.push(cards[0][0]);
    counts.push(cards[1][0]);
    counts.push(cards[2][0]);

    const forms = [];
    forms.push(cards[0][1]);
    forms.push(cards[1][1]);
    forms.push(cards[2][1]);

    const colors = [];
    colors.push(cards[0][2]);
    colors.push(cards[1][2]);
    colors.push(cards[2][2]);

    const fill = [];
    fill.push(cards[0][3]);
    fill.push(cards[1][3]);
    fill.push(cards[2][3]);

    if (
      !isEquals(counts) && !isDifferent(counts)
    ) {
      return false;
    }

    if (
      !isEquals(forms) && !isDifferent(forms)
    ) {
      return false;
    }

    if (
      !isEquals(colors) && !isDifferent(colors)
    ) {
      return false;
    }

    if (
      !isEquals(fill) && !isDifferent(fill)
    ) {
      return false;
    }

    return true;
  };

  const sets: string[][] = [];
  indexes.forEach(ixs => {
    const [a, b, c] = ixs;
    const c1 = cards[a];
    const c2 = cards[b];
    const c3 = cards[c];

    const cs = [c1, c2, c3];
    if (getIsSet(cs)) {
      sets.push(cs);
    }
  });

  console.log(sets);

  return sets;
};

const isReadyForCalc = (cards: Card[]): boolean => cards.length === NUMBER_OF_CARDS;

const createCard = (slug: string): Card => {
  // @ts-ignore
  const [ count, color, shape, fill ] = slug;

  return {
    count,
    color,
    shape,
    fill,
  };
};

function App() {
  // const startCards = [
  //   '1PWE',
  //   '1POF',
  //   '2PMS',
  //   '1PWF',
  //   '3GOF',
  //   '1GOE',
  //   '2GOF',
  //   '2POF',
  //   '2RWS',
  //   '3RWF',
  //   '2RMF',
  //   '3RWE',
  // ].map(slug => createCard(slug));

  const [ cards, setCards ] = useState<Card[]>(getAllCards().slice(0, 12));
  const [ sets, setSets ] = useState<Card[][]>([]);
  // const [ cardNumber, setCardNumber ] = useState<number>(0);

  // const [ step, setStep ] = useState<StepsValue>(StepsValue.Count);
  const [ newStep, setNewStep ] = useState<NewStepsValue>(NewStepsValue.CountColor);
  const [ actionType, setActionType ] = useState<ActionTypes>(ActionTypes.SelectCards);

  const [ count, setCount ] = useState<CountValues>(CountValues.One);
  const [ color, setColor ] = useState<ColorValues>(ColorValues.Red);
  const [ shape, setShape ] = useState<ShapeValues>(ShapeValues.Oval);
  const [ fill, setFill ] = useState<FillValues>(FillValues.Empty);

  const onCalcClick = (setSets: (sets: Card[][]) => void) => () => {
    const cds = cards.map(c => `${c.count}${c.color}${c.shape}${c.fill}`);
    console.log('cds', cds);

    const sets = onCalc(cds);
    console.log('sets', sets);

    const setCards = sets.map(slugArray => slugArray.map(slug => createCard(slug)));
    console.log('setCards', setCards);

    setSets(setCards);
  };

  return (
    <div className="App">

      <div style={{border: '1px blue solid'}}>
        <h3>Sets ({sets.length}):</h3>
        {sets.map((cards, index) => <Cards key={index} cards={cards} />)}
      </div>

      {actionType === ActionTypes.SelectCards &&
      <div>
          <div style={{border: '1px blue solid'}}>
              <h3>Selected cards:</h3>
              <Cards cards={cards} setCards={setCards} showRemoveButton={true} />
          </div>

          <div style={{border: '1px grey solid'}}>
            {!isReadyForCalc(cards) && newStep === NewStepsValue.CountColor &&
            <div className='step-1'>
                <h3>Choose count and color:</h3>
              {Object.values(ColorValues).map(color => (
                <Step1 key={color} color={color} setColor={setColor} setCount={setCount} setNewStep={setNewStep} />
              ))}
            </div>}

            {!isReadyForCalc(cards) && newStep === NewStepsValue.ShapeFill &&
            <div className='step-2'>
                <h3>Choose shape and fill:</h3>
                <Step2 selectedCards={cards} setNewStep={setNewStep} color={color} count={count} setShape={setShape} setFill={setFill} setCards={setCards} />
                <button onClick={() => setNewStep(NewStepsValue.CountColor)}>Back</button>
            </div>}
          </div>

          <span>Total selected: {cards.length}</span>

        {isReadyForCalc(cards) &&
        <button onClick={onCalcClick(setSets)}>
            Calc
        </button>}
      </div>}
    </div>
  );
}

export default App;
