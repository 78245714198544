import React, { FC } from 'react';
import { ShapeValues } from './Shape';
import { Card, NewStepsValue } from './App';
import { FillValues } from './Fill';
import CardView from './CardView';

interface ICardsProps {
  cards: Card[];
  selectedCards?: Card[];
  showRemoveButton?: boolean;

  setShape?(shape: ShapeValues): void;
  setFill?(fill: FillValues): void;
  setCards?(cards: Card[]): void;
  setNewStep?(step: NewStepsValue): void;
}

const Cards: FC<ICardsProps> = (
  {
    cards,
    selectedCards,
    setShape,
    setFill,
    setCards,
    setNewStep,
    showRemoveButton,
  }) => (
  <div className='cards'>
    {cards.map((card, index) => (
      <CardView
        key={index}
        card={card}
        cards={cards}
        index={index}
        showRemoveButton={showRemoveButton}
        setCards={setCards}
        selectedCards={selectedCards}
        setNewStep={setNewStep}
        setShape={setShape}
        setFill={setFill}
      />
    ))}
  </div>
);

export default Cards;