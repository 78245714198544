import React, { FC } from 'react';
import { Card } from '../App';
import { getColorClassName } from './Color/ColorView';
import { getShapeClassName } from './Shape/ShapeView';
import { getFillClassName } from './Fill/FillView';

interface IProps {
  card: Card;
}

const SymbolView: FC<IProps> = ({ card }) => {
  return (
    <div className='symbol'>
      <div className={`${getColorClassName(card.color)} ${getShapeClassName(card.shape)} ${getFillClassName(card.fill)}`}>
        {card.count}
        {card.color}
        {card.shape}
        {card.fill}
      </div>
    </div>
  )
};

export default SymbolView;
