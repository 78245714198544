import React, { FC } from 'react';
import { StepsValue } from './App';
import ColorView from './View/Color/ColorView';

export enum ColorValues {
  Red = 'R',
  Purple = 'P',
  Green = 'G',
}

const colorValues = [ColorValues.Green, ColorValues.Purple, ColorValues.Red];

interface IProps {
  setStep(step: StepsValue): void;
  setColor(color: ColorValues): void;
}

const Color: FC<IProps> = ({ setStep, setColor }) => (
  <div>
    {colorValues.map(v => (
      <div key={v} onClick={() => {
        setStep(StepsValue.Shape);
        setColor(v)
      }}>
        <ColorView color={v}/>
      </div>
    ))}
  </div>
);

export default Color;
