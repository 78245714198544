import React, { FC } from 'react';
import { CountValues } from '../../Count';

interface IProps {
  count: CountValues;
}

export const getCountClassName = (count: CountValues): string => {
  let countString = '';
  switch (count) {
    case CountValues.One:
      countString = 'one';
      break;
    case CountValues.Two:
      countString = 'two';
      break;
    case CountValues.Three:
    default:
      countString = 'three';
      break;
  }

  return `count ${countString}`;
};

const CountView: FC<IProps> = ({ count }) => (
  <h1>
    {count}
  </h1>
);

export default CountView;
