import React, { FC } from 'react';
import { ColorValues } from '../../Color';

interface IProps {
  color: ColorValues;
}

export const getColorClassName = (color: ColorValues): string => {
  let colorString = '';
  switch (color) {
    case ColorValues.Green:
      colorString = 'green';
      break;
    case ColorValues.Purple:
      colorString = 'purple';
      break;
    case ColorValues.Red:
    default:
      colorString = 'red';
      break;
  }

  return `color ${colorString}`;
};

const ColorView: FC<IProps> = ({ color }) => {
  const className = getColorClassName(color);

  return (
    <div className={`color ${className}`}>
      {color}
    </div>
  );
};

export default ColorView;
