import React, { FC } from 'react';
import { ColorValues } from './Color';
import { CountValues } from './Count';
import { getColorClassName } from './View/Color/ColorView';
import { getCountClassName } from './View/Count/CountView';
import { NewStepsValue } from './App';

interface Step1Props {
  color: ColorValues;

  setCount(count: CountValues): void;
  setColor(color: ColorValues): void;
  setNewStep(step: NewStepsValue): void;
}

const Step1: FC<Step1Props> = ({ color, setCount, setColor, setNewStep }) => (
  <div className={getColorClassName(color)}>
    {Object.values(CountValues).map(count => (
      <div key={count} className={getCountClassName(count)} onClick={() => {
        setColor(color);
        setCount(count);
        setNewStep(NewStepsValue.ShapeFill);
      }}>
        {count}
      </div>
    ))}
  </div>
);

export default Step1;