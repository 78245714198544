import React, { FC } from 'react';
import { ShapeValues } from '../../Shape';
import { getColorClassName } from '../Color/ColorView';
import { ColorValues } from '../../Color';

interface IProps {
  color: ColorValues;
  shape: ShapeValues;
}

export const getShapeClassName = (shape: ShapeValues): string => {
  let shapeString = ``;
  switch (shape) {
    case ShapeValues.Oval:
      shapeString = `oval`;
      break;
    case ShapeValues.Diamond:
      shapeString = `diamond`;
      break;
    case ShapeValues.Wave:
    default:
      shapeString = `wave`;
      break;
  }

  return `shape ${shapeString}`;
};

const ShapeView: FC<IProps> = ({ shape, color }) => (
  <div className={`${getShapeClassName(shape)} ${getColorClassName(color)}`}>
    {shape}
  </div>
);

export default ShapeView;
