import React, { FC } from 'react';
import FillView from './View/Fill/FillView';
import { ColorValues } from './Color';

export enum FillValues {
  Empty = 'E',
  Stripe = 'S',
  Full = 'F',
}

const fillValues = [FillValues.Empty, FillValues.Full, FillValues.Stripe];

interface IProps {
  color: ColorValues;

  onFillClick: (v: FillValues) => () => void;
}

const Fill: FC<IProps> = (
  {
    onFillClick,
    color,
  }) => (
  <div>
    {fillValues.map(v => (
      <div key={v} onClick={onFillClick(v)}>
        <FillView fill={v} color={color} />
      </div>
    ))}
  </div>
);

export default Fill;
