import React, { FC } from 'react';
import { FillValues } from '../../Fill';
import { ColorValues } from '../../Color';
import { getColorClassName } from '../Color/ColorView';

interface IProps {
  fill: FillValues;
  color: ColorValues;
}

export const getFillClassName = (fill: FillValues): string => {
  let fillString = '';
  switch (fill) {
    case FillValues.Empty:
      fillString = `empty`;
      break;
    case FillValues.Full:
      fillString = `full`;
      break;
    case FillValues.Stripe:
    default:
      fillString = `stripe`;
      break;
  }

  return `fill ${fillString}`;
};

const FillView: FC<IProps> = ({ fill, color }) => {
  return (
    <div className={`${getFillClassName(fill)} ${getColorClassName(color)}`}>
      {fill}
    </div>
  );
};

export default FillView;
