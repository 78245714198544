import React, { FC } from 'react';
import { StepsValue } from './App';
import ShapeView from './View/Shape/ShapeView';
import { ColorValues } from './Color';
import { CountValues } from './Count';

export enum ShapeValues {
  Wave = 'W',
  Diamond = 'M',
  Oval = 'O',
}

const shapeValues = [ShapeValues.Diamond, ShapeValues.Wave, ShapeValues.Oval];

interface IProps {
  color: ColorValues;
  count: CountValues;

  setStep(step: StepsValue): void;
  setShape(shape: ShapeValues): void;
}

export const getCountValues = (count: CountValues) => {
  const countValues: number[] = [];
  let counter = 1;
  for (let i = 0; i < parseInt(count, 10); i++) {
    countValues.push(counter);
    counter++;
  }

  return countValues;
};

const Shape: FC<IProps> = ({ setStep, setShape, color, count }) => (
  <div>
    {shapeValues.map(v => (
      <div key={v} onClick={() => {
        setStep(StepsValue.Fill);
        setShape(v);
      }}>
        {getCountValues(count).map(c => <ShapeView key={c} shape={v} color={color} />)}
      </div>
    ))}
  </div>
);

export default Shape;
